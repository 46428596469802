<template>
  <div>
    <query-form
      class="content_block"
      label-width="100px"
      @search="handleSearch"
      :span="8"
      @reset="handleReset"
      :defaultCollapsed="false"
    >
      <el-form-item label="仓库编码：" prop="code">
        <el-input v-model="form.code" placeholder="请输入仓库编码"></el-input>
      </el-form-item>
      <el-form-item label="仓库名称：" prop="name">
        <el-input v-model="form.name" placeholder="请输入仓库名称"></el-input>
      </el-form-item>
      <el-form-item label="仓库类型：" prop="typeId">
        <el-row type="flex">
          <el-select v-model="form.typeId" style="flex: 1">
            <el-option :value="undefined" label="全部">全部</el-option>
            <el-option v-for="item in outTypeList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
          <i
            v-auth="'sparePart:stock:type:manager'"
            class="el-icon-setting"
            title="管理仓库类型"
            @click="$router.push({ name: 'SPAREPART_STOCK_TYPE_MANAGER' })"
          ></i>
        </el-row>
      </el-form-item>
      <el-form-item label="创建日期：" prop="dateRange">
        <el-date-picker
          v-model="form.dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="customerList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      @current-change="getTableList"
      @sort-change="getTableList"
      :leftToolbar="tableButtons"
    />
    <el-dialog
      :title="`${addType === 1 ? '新增' : '编辑'}仓库`"
      :visible.sync="addStockDialog"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="cancelStockHandle"
    >
      <el-form :model="addForm" label-width="120px" :rules="rules" ref="addFormRef">
        <el-row>
          <el-col :span="12">
            <el-form-item v-if="addType === 2" label="仓库编码：" prop="code">
              <div>{{ addForm.code }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库名称：" prop="name">
              <el-input v-model="addForm.name" placeholder="请输入仓库名称" :maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库类型：" prop="typeId" required>
              <el-select v-model="addForm.typeId" @change="typeIdChangeHandle">
                <el-option v-for="item in outTypeList" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人姓名：" prop="contactId">
              <el-select
                v-model="addForm.contactId"
                remote
                filterable
                clearable
                placeholder="请输入联系人姓名"
                :remote-method="remoteMethod"
                @change="contactIdChangeHandle"
                :loading="loading"
              >
                <el-option v-for="item in contactList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话：" prop="contactNumber">
              <el-input v-model="addForm.contactNumber" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="联系人地址：" prop="originAreaId">
              <area-cascader
                v-if="addType !== 0"
                :provinceId.sync="addForm.originProvinceId"
                :provinceName.sync="addForm.originProvinceName"
                :cityId.sync="addForm.originCityId"
                :cityName.sync="addForm.originCityName"
                :areaId.sync="addForm.originAreaId"
                :areaName.sync="addForm.originAreaName"
              />
            </el-form-item>
            <el-form-item prop="originAddress">
              <el-input v-model="addForm.originAddress" placeholder="请输入详细地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelStockHandle">取 消</el-button>
        <el-button type="primary" @click="addStockHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import _ from 'lodash';
import { getWarehouseList, getInventoryOutTypeList, addWarehouse, editWarehouse } from '@/api/stock.js';
import AreaCascader from '@/components/common/areaCascader';
import systemSetting from '@/api/systemSetting.js';
import { dateFormat } from '@/utils/dateFormat.js';

export default {
  name: 'STOCKIN_INVENTORY_MANAGER',
  mixins: [TablePaginationMixin],
  components: {
    AreaCascader,
  },
  data () {
    return {
      loading: false,
      contactList: [],
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入仓库名称' }],
        typeId: [{ required: true, trigger: 'change', message: '请选择仓库类型' }],
      },
      addStockDialog: false,
      addForm: {
        id: undefined,
        code: undefined,
        typeCode: undefined,
        name: '',
        typeId: '',
        typeName: '',
        contactId: undefined,
        contactName: undefined,
        contactNumber: undefined,
        originAreaId: '',
        originAreaName: '',
        originCityId: '',
        originCityName: '',
        originProvinceId: '',
        originProvinceName: '',
      },
      addType: 0, // 1新增， 2编辑
      outTypeList: [],
      tableButtons: [
        {
          name: '新增',
          status: 'primary',
          size: 'small',
          permission: 'sparePart:stock:add',
          click: () => {
            this.addType = 1;
            this.addStockDialog = true;
          },
        },
      ],
      form: {
        code: undefined,
        name: undefined,
        typeId: undefined,
        dateRange: [],
        startTime: undefined,
        endTime: undefined,
      },
      customerList: [],
      columnList: [
        {
          tooltip: true,
          label: '仓库编码',
          minWidth: '180px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '仓库名称',
          minWidth: '180px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '仓库类型',
          minWidth: '180px',
          prop: 'typeName',
        },
        {
          tooltip: true,
          label: '联系人姓名',
          minWidth: '180px',
          prop: 'contactName',
        },
        {
          tooltip: true,
          label: '联系电话',
          minWidth: '180px',
          prop: 'contactNumber',
        },
        {
          tooltip: true,
          label: '联系地址',
          minWidth: '180px',
          prop: 'originAddress',
          render: (h, {row}) => {
            return `${row.originProvinceName}-${row.originCityName}-${row.originAreaName}-${row.originAddress}`;
          },
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '180px',
          prop: 'createTime',
          format: (val) => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          tooltip: true,
          label: '最后操作时间',
          minWidth: '180px',
          prop: 'lastUpdateTime',
          format: (val) => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          label: '操作',
          minWidth: '180px',
          prop: 'actions',
          render: (h, { row }) => {
            let actions = [];
            actions.push(
              <span
                v-auth={'sparePart:stock:update'}
                class='cursor-pointer text-primary ml-1 '
                onClick={this.openBackDialog.bind(this, row)}
              >
                  编辑
              </span>,
            );
            return actions;
          },
        },
      ],
      curRow: {},
    };
  },
  created () {
    this.getInventoryOutTypeListHandle();
    this.remoteMethod();
  },
  methods: {
    cancelStockHandle () {
      this.addType = 0;
      this.$refs.addFormRef && this.$refs.addFormRef.resetFields();
      this.addForm.originAreaId = '';
      Object.keys(this.addForm).forEach((i) => {
        this.addForm[i] = undefined;
      });
      this.addStockDialog = false;
    },
    addStockHandle () {
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          const param = {
            ...this.addForm,
          };
          const rs = this.addType === 1 ? await addWarehouse(param) : await editWarehouse(param);
          if (rs.heads.code === 200) {
            this.$message.success(`${this.addType === 1 ? '新增' : '编辑'}仓库成功`);
            this.cancelStockHandle();
            this.handleSearch();
          }
        }
      });
    },
    async remoteMethod (query) {
      this.loading = true;
      const rs = await systemSetting.getEmployLists({ name: query });
      this.loading = false;
      this.contactList = rs.body?.list.map((i) => ({ id: i.id, name: i.name, contactNumber: i.phoneNumber }));
    },
    contactIdChangeHandle (id) {
      if (!id) {
        this.addForm.contactNumber = '';
        this.addForm.contactName = '';
        return;
      }
      this.addForm.contactNumber = this.contactList.find((i) => i.id === id).contactNumber;
      this.addForm.contactName = this.contactList.find((i) => i.id === id).name;
    },
    typeIdChangeHandle (id) {
      const { name, code } = this.outTypeList.find((i) => i.id === id);
      this.addForm.typeName = name;
      this.addForm.typeCode = code;
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      Object.keys(this.form).forEach((i) => {
        this.form[i] = undefined;
      });
      this.form.dateRange = [];
      this.handleSearch();
    },
    async getTableList () {
      if (this.form.dateRange.length) {
        this.form.startTime = +new Date(this.form.dateRange[0]);
        this.form.endTime = +new Date(this.form.dateRange[1]);
      }
      const param = {
        ...this.form,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
      };
      const rs = await getWarehouseList(param);
      if (rs.heads.code === 200) {
        const { list, total } = rs.body;
        this.customerList = list;
        this.pagination.total = total;
      }
    },
    openBackDialog (row) {
      this.remoteMethod();
      this.addForm = { ...this.addForm, ...row };
      this.addType = 2;
      this.addStockDialog = true;
    },
    async getInventoryOutTypeListHandle () {
      const rs = await getInventoryOutTypeList();
      if (rs.heads.code === 200) {
        const { list } = rs.body;
        this.outTypeList = _.cloneDeep(list);
      }
    },
  },
};
</script>
<style scoped lang="scss">
  .el-icon-setting {
    font-size: 28px;
    margin-left: 8px;
    border: 1px #dcdff6 solid;
    border-radius: 4px;
    color: #dcdff6;
    height: 32px;
    line-height: 32px;
    cursor: pointer;

    &:hover {
      background-color: #409eff;
      border-color: #409eff;
    }
  }
</style>
